export default function config() {
  const ACCESS_ABILITY_INVENTORY = {action: 'change', subject: 'Inventory'}
  // Table Handlers
  const tableColumnsCatalog = [
    {
      key: 'event',
      label: 'Event',
      thStyle: {
        width: '40%',
      },
    },
    {
      key: 'order',
      label: 'Order',
      thStyle: {
        width: '10%',
      },
    },
    {
      key: 'date',
      label: 'Date',
      thStyle: {
        width: '30%',
      },
    },
    {
      key: 'user',
      label: 'User',
      thStyle: {
        width: '20%',
      },
    },
  ]

  return {
    tableColumnsCatalog,
    ACCESS_ABILITY_INVENTORY,
  }
}
