<template>
  <div>
    <portal to="body-top">
      <h4
        v-if="inventoryAsset"
        class="font-medium-4 text-center font-weight-bolder mb-1"
      >
        {{ $t('Asset') }} {{ inventoryAsset.id }} {{ `— ${inventoryItem.name ? inventoryItem.name : '— '}` }}
      </h4>
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsCatalog"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getInventoryAudit`,
        data: {
          'inventory_item_id': inv_id
        }
      }"
      :can-create="ACCESS_ABILITY_INVENTORY"
    >
      <template #cell(event)="{ data }">
        {{ data.item.event }}
      </template>
      <template #cell(date)="{ data }">
        {{ convertTZ(data.item.updated_at) }}
      </template>
      <template #cell(user)="{ data }">
        {{ data.item.user.firstname }}
        {{ data.item.user.lastname }}
      </template>
    </l-table>
    <portal to="body-footer">
      <div class="d-flex mt-2 pb-1 justify-content-between">
        <div>
          <b-button
            class="cancelBtn font-medium-1 font-weight-bolder"
            variant="outline-primary"
            @click="back"
          >
            {{ $t('Back to View') }}
          </b-button>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import { BButton } from 'bootstrap-vue'
import config from './config'

export default {
  name: 'Audit',
  components: {
    LTable,
    BButton,
  },
  data() {
    return {
      inv_id: this.$route.params.inv_id,
      id: this.$route.params.id,
    }
  },
  computed: {
    inventoryItem() {
      return this.$store.state[this.MODULE_NAME].inventory?.product ?? ''
    },
    inventoryAsset() {
      return this.$store.state[this.MODULE_NAME].inventory
    },
  },
  created() {
    console.log(this.id)
    this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_ID`, this.id)
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.inv_id)
  },
  methods: {
    back() {
      this.$router.push({ name: 'home-inventory-item-update' })
    },
    converterTZ(data) {
      return this.convertTZ(data.item.updated_at)
    },
  },
  setup() {
    const MODULE_NAME = 'inventories'

    const { tableColumnsCatalog, ACCESS_ABILITY_INVENTORY } = config()
    return {
      tableColumnsCatalog,
      MODULE_NAME,
      ACCESS_ABILITY_INVENTORY,
    }
  },
}
</script>
